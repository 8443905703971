import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "Settings" }
const _hoisted_2 = { class: "Settings--Box" }
const _hoisted_3 = { class: "Settings--Tabs" }
const _hoisted_4 = { class: "selected" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SettingsPassword = _resolveComponent("SettingsPassword")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('suite_complete_signup')), 1)
      ]),
      _createVNode(_component_SettingsPassword, {
        isAfterLogin: "",
        onSavedData: _cache[0] || (_cache[0] = ($event: any) => (_ctx.lastStep()))
      })
    ])
  ]))
}