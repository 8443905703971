
import { defineComponent } from 'vue';
import SettingsPassword from '@/views/SettingsPassword'
import store from '@/store'
export default defineComponent({
  name : 'AfterLogin',
  components:{
    SettingsPassword
  },
  methods:{
    async lastStep(){
      await store.dispatch('removeCookieChangePassword')
      this.$router.push( { name: 'dashboard', } )
    }
  }
})
